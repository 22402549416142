.profile-modal-wrapper-desktop {
    position: fixed;
    height: 100vh;
    width: 100%;
    top:0;
    left: 0;
    z-index: -9998 !important;
    opacity: 0;
    transition: opacity .2s ease;
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px) opacity(0);
}

.profile-modal-wrapper-desktop.open {
    z-index: 9998 !important;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(1px) opacity(1);
}

.profile-modal-wrapper-desktop .profile-modal-desktop {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
    gap: 16px;
    box-sizing: border-box;
    box-shadow: 1px 1px 50px 0px rgba(0, 0, 0, 0.20);
    border-radius: 24px;
    background-color: var(--main-white, #FFF);
}

.profile-modal-wrapper-desktop .header-and-close-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.profile-modal-wrapper-desktop .profile-modal-first-btns-wrapper {
    display: grid;
    width: 100%;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    margin-top: 40px;
}

.profile-modal-wrapper-desktop .avatar-desktop-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile-modal-wrapper-desktop .common-modal-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 48px;
}

.profile-modal-wrapper-desktop .common-modal-wrapper .btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}