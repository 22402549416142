.food-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    min-width: 148px !important;
    position: relative;
}

.food-card-wrapper.desktop {
    min-width: 184px !important;
}

.food-card-wrapper.restaurant {
    min-width: 160px !important;
}

.food-card-wrapper .food-count-wrapper {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 5;
}

.food-card-wrapper .food-count-wrapper:hover {
    cursor: pointer;
}

.food-card-wrapper .card-img {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    border-radius: 16px;
}

.food-card-wrapper .card-info-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    flex-direction: column;
}

.food-card-wrapper .card-info-wrapper:hover {
    cursor: pointer;
}

.food-card-wrapper .card-name {
    color: var(--text-secondary, #5D5E64);
}

.food-card-wrapper .card-price {
    color: var(--text-primary, #2C2D2E);
    font-family: InterDisplay-SemiBold, serif;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
}