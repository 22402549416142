.restaurant-info-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    box-sizing: border-box;
    padding: 8px 16px 16px;
}

.restaurant-info-card-wrapper .img-and-cards-wrapper {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px
}

.restaurant-info-card-wrapper .img-and-cards-wrapper .rest-img {
    border-radius: 16px;
    aspect-ratio: 1;
}

.restaurant-info-card-wrapper .img-and-cards-wrapper .cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
    width: 100%;
}

.restaurant-info-card-wrapper .img-and-cards-wrapper .cards-wrapper .card {
    border-radius: 16px;
    aspect-ratio: 1;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.restaurant-info-card-wrapper .img-and-cards-wrapper .cards-wrapper .card.green {
    background: var(--main-ym-green, #00DF5F);
}

.restaurant-info-card-wrapper .img-and-cards-wrapper .cards-wrapper .card.gray {
    background: var(--gray-light, #F3F4F6);
}

.restaurant-info-card-wrapper .img-and-cards-wrapper .cards-wrapper .card.white {
    color: var(--main-white, #FFFFFF)
}

.restaurant-info-card-wrapper .img-and-cards-wrapper .cards-wrapper .card .rate {
    color: var(--main-white, #FFFFFF);
    font-family: InterDisplay-SemiBold, serif;
    font-size: 23.312px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.466px;
}

.restaurant-info-card-wrapper-desktop {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    box-sizing: border-box;
    gap: 24px;
    width: 100%;
    padding: 16px 86px;
}

.restaurant-info-card-wrapper-desktop .img-and-cards-wrapper-desktop {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    height: 400px;
}

.restaurant-info-card-wrapper-desktop .img-and-cards-wrapper-desktop.tablet {
    height: 256px;
}

.restaurant-info-card-wrapper-desktop .img-and-cards-wrapper-desktop .rest-img-desktop {
    border-radius: 32px;
    flex-shrink: 0;
}

.restaurant-info-card-wrapper-desktop .img-and-cards-wrapper-desktop .squares-wrapper-desktop {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 400px;
}

.restaurant-info-card-wrapper-desktop .img-and-cards-wrapper-desktop .squares-wrapper-desktop.tablet {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 256px;
}

.restaurant-info-card-wrapper-desktop .img-and-cards-wrapper-desktop .squares-wrapper-desktop .card-desktop {
    border-radius: 32px;
    aspect-ratio: 1;
    width: 128px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background: var(--main-ym-green, #00DF5F);
    color: white;
}

.restaurant-info-card-wrapper-desktop .img-and-cards-wrapper-desktop .squares-wrapper-desktop .card-desktop.tablet {
    width: 80px;
    border-radius: 16px;
}

.restaurant-info-card-wrapper-desktop .restaurant-info-desktop {
    display: flex;
    width: 100%;
    height: 400px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    background: var(--light-colors-light, #F6F9FA);
    border-radius: 32px;
    gap: 4px;
}

.restaurant-info-card-wrapper-desktop .restaurant-info-desktop.tablet {
    height: 256px;
}

.restaurant-info-card-wrapper-desktop .restaurant-info-desktop .info-tags-wrapper-desktop {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    position: relative;
}

.restaurant-info-card-wrapper-desktop .restaurant-info-desktop .top-grad {
    content: '';
    position: absolute;
    bottom: -22px;
    background: linear-gradient(to top, transparent, var(--light-colors-light));
    height: 18px;
    display: flex;
    pointer-events: none;
    width: 100%;
}

.restaurant-info-card-wrapper-desktop .restaurant-info-desktop .bottom-grad {
    content: '';
    position: absolute;
    top: -22px;
    background: linear-gradient(to bottom, transparent, var(--light-colors-light));
    height: 18px;
    display: flex;
    pointer-events: none;
    width: 100%;
}