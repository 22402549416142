.footer-wrapper {
    display: flex;
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    box-sizing: border-box;
    width: 100%;
    background: var(--light-colors-light, #F6F9FA);
}

.footer-wrapper .download-btns {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.footer-wrapper .social-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 39px;
    width: 100%;
    box-sizing: border-box;
}

.footer-wrapper .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.footer-wrapper .info-wrapper .info-text {
    color: var(--text-tetriary, #87898F);
    text-align: center;
    font-family: Lato-500, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footer-wrapper .info-wrapper .policy-link {
    text-decoration: underline;
    color: var(--text-tetriary, #87898F);
    text-align: center;
    font-family: Lato-500, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footer-wrapper .info-wrapper .policy-link:active {
    cursor: pointer;
    color: var(--text-secondary);
}

.footer-wrapper-desktop {
    display: flex;
    align-self: stretch;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    background: var(--light-colors-light, #F6F9FA);
    box-sizing: border-box;
    padding: 64px 86px;
    align-items: center;
}

.footer-internal-wrapper-desktop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    flex-direction: row;
    background: var(--light-colors-light, #F6F9FA);
    box-sizing: border-box;
    max-width: 1268px;
}

.footer-wrapper-desktop .footer-left-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    max-width: 400px;
    margin-right: 32px;
}

.footer-wrapper-desktop .footer-right-wrapper {
    display: flex;
    min-width: 328px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
}

.footer-wrapper-desktop .footer-left-wrapper .social-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 39px;
    width: 100%;
    box-sizing: border-box;
}

.footer-wrapper-desktop .footer-left-wrapper .info-text {
    color: var(--text-tetriary, #87898F);
    text-align: left;
    font-family: Lato-500, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footer-wrapper-desktop .footer-desktop-links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.footer-wrapper-desktop .policy-link {
    text-decoration: underline;
    color: var(--text-tetriary, #87898F);
    text-align: center;
    font-family: Lato-500, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footer-wrapper-desktop .policy-link:hover {
    cursor: pointer;
    color: var(--text-secondary);
}
