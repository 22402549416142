.not-found-page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 80px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.not-found-page-wrapper .main-not-found-wrapper {
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;
    gap: 28px;
}

.not-found-page-wrapper .not-found-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    margin-bottom: 24px;
}

.not-found-page-wrapper .not-found-text-wrapper .gray {
    color: var(--text-primary, #2C2D2E);
    text-align: center;
}

.not-found-page-wrapper .not-found-text-wrapper .gray-sec {
    color: var(--text-tetriary, #87898F);
    text-align: center;
}

.not-found-page-wrapper-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 80px;
    box-sizing: border-box;
}

.not-found-page-wrapper-desktop .not-found-info-desktop {
    width: 100%;
    gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
    margin-top: 100px;
}

.not-found-page-wrapper-desktop .not-found-info-desktop .info-wrpr {
    align-items: center;
    justify-content: center;
}