.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    width: 136px;
    height: 39px;
    background: var(--main-ym-green, #00DF5F);
    box-shadow: 0.263px 0.263px 30px 0 rgba(0, 223, 95, 0.20);
    flex-shrink: 0;

    color: var(--main-white, #FFF);
    font-family: AlegreyaSans-Bold, serif;
    font-size: 26px;
    font-style: normal;
    line-height: normal;
}

.logo.hovered:hover {
    cursor: pointer;
}