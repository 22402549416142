.download-btn-wrapper {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    box-sizing: border-box;
}

.download-btn-wrapper .green {
    color: var(--main-ym-green, #00DF5F);
}

.download-btn-wrapper .white {
    color: var(--main-white, #FFF);
}