.navbar-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;

    border-top: 1px solid var(--gray-light, #F3F4F6);
    background: var(--main-white, #FFF);
}

.navbar-wrapper.closed {
    bottom: -64px
}
