.open-rest-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--gray-light, #F3F4F6);
    box-sizing: border-box;
}

.open-rest-card-wrapper.desktop {
    width: 184px;
    height: 184px;
    padding: 51px;
}

.open-rest-card-wrapper.mobile {
    width: 148px;
    height: 148px;
    padding: 50px;
}