.order-page-wrapper {
    display: flex;
    width: 100%;
    padding-left: 8px;
    padding-right: 4px;
    margin-bottom: 61px;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
}

.order-page-wrapper.empty {
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-right: 8px;
}

.order-page-wrapper .empty-order-wrapper {
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;
    gap: 28px;
}

.order-page-wrapper .empty-order-wrapper .empty-order-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    margin-bottom: 24px;
}

.order-page-wrapper .position-cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 8px 12px 16px 8px;
}

.order-page-wrapper .order-page-header {
    position: relative;
}

.order-page-wrapper .top-grad {
    content: '';
    position: absolute;
    top: 29px;
    background: linear-gradient(to top, transparent, white);
    height: 18px;
    display: flex;
    pointer-events: none;
}

.order-page-wrapper .checkout-order-btn-wrapper {
    display: flex;
    width: 100%;
    padding: 8px 12px 8px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    box-sizing: border-box;
    position: relative;
}

.order-page-wrapper .checkout-order-btn-wrapper .bottom-grad {
    content: '';
    position: absolute;
    top: -17px;
    background: linear-gradient(to bottom, transparent, white);
    height: 18px;
    display: flex;
    pointer-events: none;
    z-index: 33;
}

.order-page-wrapper .order-sending-info-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 8px;
    padding-right: 12px;
    gap: 28px;
    padding-left: 8px;
}

.order-page-wrapper .order-sending-info-wrapper .order-sending-info-text-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 32px;
    padding-right: 32px;
    gap: 8px;
}

.order-page-wrapper .order-accepted-info-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 8px;
    padding-right: 4px;
    gap: 28px;
}

.order-page-wrapper .order-accepted-info-wrapper .waiter-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.order-page-wrapper .order-accepted-info-wrapper .hdr-wrapper {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.empty-order-wrapper-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 80px;
    box-sizing: border-box;
}

.empty-order-wrapper-desktop .empty-order-info-desktop {
    width: 100%;
    gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 50px;
}

.empty-order-wrapper-desktop .empty-order-info-desktop .info-wrpr {
    align-items: center;
    justify-content: center;
}

.order-page-wrapper-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding-top: 112px;
    padding-bottom: 32px;
    background-color: var(--light-colors-light);
}

.order-page-wrapper-desktop .order-wrapper-desktop-popup {
    display: flex;
    flex-direction: column;
    padding: 32px 16px 32px 32px;
    align-items: flex-start;
    gap: 4px;
    box-sizing: border-box;
    box-shadow: 1px 1px 50px 0px rgba(0, 0, 0, 0.20);
    border-radius: 24px;
    position: relative;
    background-color: white;
}

.order-page-wrapper-desktop .order-wrapper-desktop-popup .order-food-pos-desktop-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
    gap: 32px;
    box-sizing: border-box;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 16px;
}

.order-page-wrapper-desktop .order-wrapper-desktop-popup .top-grad {
    content: '';
    position: absolute;
    bottom: -22px;
    background: linear-gradient(to top, transparent, var(--main-white));
    height: 18px;
    display: flex;
    pointer-events: none;
    width: 100%;
    z-index: 9;
}

.order-page-wrapper-desktop .order-wrapper-desktop-popup .bottom-grad {
    content: '';
    position: absolute;
    bottom: 80px;
    z-index: 9;
    background: linear-gradient(to bottom, transparent, var(--main-white));
    height: 18px;
    display: flex;
    pointer-events: none;
    width: 100%;
}

.order-page-wrapper-desktop .order-wrapper-desktop-popup .order-btn-desktop-wrapper {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
}

.order-page-wrapper-desktop .order-sending-wrapper-desktop {
    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.order-page-wrapper-desktop .order-sending-wrapper-desktop .order-sending-info-wrapper-desktop {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.order-page-wrapper-desktop .accepted-order-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.order-page-wrapper-desktop .accepted-order-wrapper .hdr-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;
}

.order-page-wrapper-desktop .accepted-order-wrapper .waiter-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}