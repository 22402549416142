.warning-all-wrapper .react-modal-sheet-backdrop {
    background-color: var(--special-dark-layer, rgba(0, 0, 0, 0.35)) !important;
}

.warning-all-wrapper .react-modal-sheet-container {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.warning-sheet-wrapper {
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-top: 16px;
    position: relative;
    gap: 16px;
}

.warning-all-wrapper .warning-sheet-wrapper .btns-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    box-sizing: border-box;
    width: 100%;
}

.warning-all-wrapper .react-modal-sheet-drag-indicator {
    width: 24px !important;
    background: var(--gray-dark, #C6C9D1) !important;
}

.warning-all-wrapper .react-modal-sheet-header {
    height: 24px !important;
}

.product-warning-absolute-wrapper-desktop {
    position: fixed;
    width: 0;
    height: 0;
    overflow: hidden;
    top:0;
    left: 0;
    z-index: -999999999 !important;
    opacity: 0;
    transition: opacity .2s ease;
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-warning-absolute-wrapper-desktop.open {
    height: 100vh;
    width: 100%;
    z-index: 999999 !important;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.35);
}

.product-warning-absolute-wrapper-desktop .product-warning-wrapper-desktop {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
    gap: 16px;
    box-sizing: border-box;
    box-shadow: 1px 1px 50px 0px rgba(0, 0, 0, 0.20);
    border-radius: 24px;
    background-color: var(--main-white, #FFF);
}

.product-warning-absolute-wrapper-desktop .product-warning-wrapper-desktop .header-and-close-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.product-warning-absolute-wrapper-desktop .product-warning-wrapper-desktop .btns-wrapper-desktop {
    display: grid;
    width: 100%;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    margin-top: 40px;
}