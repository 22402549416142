.image-picker-btn-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.image-picker-all-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.img-picker-btn-all-wrapper .react-modal-sheet-backdrop {
    background-color: var(--special-dark-layer, rgba(0, 0, 0, 0.35)) !important;
}

.img-picker-btn-all-wrapper .react-modal-sheet-container {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.image-picker-sheet-wrapper {
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-top: 16px;
}

.img-picker-btn-all-wrapper .react-modal-sheet-drag-indicator {
    width: 24px !important;
    background: var(--gray-dark, #C6C9D1) !important;
}

.img-picker-btn-all-wrapper .react-modal-sheet-header {
    height: 24px !important;
}

.image-picker-absolute-wrapper-desktop {
    position: fixed;
    height: 100vh;
    width: 100%;
    top:0;
    left: 0;
    z-index: -9998 !important;
    opacity: 0;
    transition: opacity .2s ease;
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px) opacity(0);
}

.image-picker-absolute-wrapper-desktop.open {
    z-index: 9998 !important;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(1px) opacity(1);
}

.image-picker-absolute-wrapper-desktop .picker-popup {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
    gap: 12px;
    box-sizing: border-box;
    box-shadow: 1px 1px 50px 0px rgba(0, 0, 0, 0.20);
    border-radius: 24px;
    background-color: var(--main-white, #FFF);
}