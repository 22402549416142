.order-history-item-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 8px;
}

.order-history-item-wrapper.desktop {
    gap: 6px;
    padding: 16px;
}

.order-history-item-wrapper:hover {
    cursor: pointer;
}

.order-history-item-wrapper .h-wrpr {
    display: flex;
    gap: 4px;
}

.order-history-item-wrapper .total-price-wrapper {
    display: flex;
    gap: 4px;
    margin-top: 8px;
}

.order-history-item-wrapper .total-price-wrapper.desktop {
    margin-top: 10px;
    margin-bottom: 10px;
}