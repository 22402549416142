.restaurant-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    box-sizing: border-box;
}

.restaurant-section-wrapper.desktop {
    gap: 24px;
}

.restaurant-section-wrapper .positions-wrapper::-webkit-scrollbar {
    display:none;
}

.restaurant-section-wrapper .positions-wrapper {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    width: 100%;
    flex-shrink: 0;
    padding-left: 16px;
    padding-right: 16px;
    gap: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;
}

.restaurant-section-wrapper .positions-wrapper.desktop {
    gap: 32px;
    padding: 0;
}

.restaurant-section-wrapper .name-and-btn-wrapper {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding-left: 16px;
    gap: 8px;
    align-items: center;
}

.restaurant-section-wrapper .name-and-btn-wrapper:hover {
    cursor: pointer;
}

.restaurant-section-wrapper .name-and-btn-wrapper.desktop {
    padding-left: 0;
}

.restaurant-section-wrapper .rest-section-tags-wrapper {
    display: flex;
    flex-direction: row;
    overflow: scroll;
}