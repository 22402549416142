.rest-info-btn-all-wrapper .restaurant-info-sheet-wrapper {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-top: 16px;
}

.rest-info-btn-all-wrapper .react-modal-sheet-drag-indicator {
    width: 24px !important;
    background: var(--gray-dark, #C6C9D1) !important;
}

.rest-info-btn-all-wrapper .react-modal-sheet-header {
    height: 24px !important;
}

.rest-info-btn-all-wrapper .react-modal-sheet-backdrop {
    background-color: var(--special-dark-layer, rgba(0, 0, 0, 0.35)) !important;
}

.rest-info-btn-all-wrapper .react-modal-sheet-container {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.rest-info-btn-all-wrapper .tags-and-text-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    box-sizing: border-box;
}

.rest-info-btn-all-wrapper .gray {
    color: var(--text-secondary, #5D5E64);
}

.rest-info-btn-all-wrapper .rest-info-tags-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 2px;
}

.rest-description-text-wrapper {
    overflow: scroll;
    box-sizing: border-box;
    padding-bottom: 12px;
    padding-top: 10px;
}

.rest-info-btn-all-wrapper .address-wrapper {
    position: relative;
    margin-bottom: 16px;
    box-sizing: border-box;
    padding-top: 4px;
}

.rest-info-btn-all-wrapper .address-wrapper .bottom-grad {
    content: '';
    position: absolute;
    top: -17px;
    background: linear-gradient(to bottom, transparent, white);
    height: 18px;
    display: flex;
    pointer-events: none;
}

.rest-info-btn-all-wrapper .top-grad {
    content: '';
    position: absolute;
    bottom: -17px;
    background: linear-gradient(to top, transparent, white);
    height: 18px;
    display: flex;
    pointer-events: none;
}