.order-product-info-card-wrapper {
    display: flex;
    padding: 2px 0px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    box-sizing: border-box;
}

.order-product-info-card-wrapper.desktop {
    gap: 24px;
}

.order-product-info-card-wrapper .pos-image {
    width: 64px;
    height: auto;
    aspect-ratio: 1;
    border-radius: 6px;
}

.order-product-info-card-wrapper .name-and-description-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    box-sizing: border-box;
    width: 100%;
}

.order-product-info-card-wrapper .name-and-description-wrapper.desktop {
    gap: 24px;
}

.order-product-info-card-wrapper .name-and-description-wrapper .text-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    box-sizing: border-box;
}

.order-product-info-card-wrapper .name-and-description-wrapper .price-and-weight-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.order-product-info-card-wrapper .name-and-description-wrapper .price-and-weight-wrapper.desktop {
    gap: 24px;
    justify-content: left;
}

.order-product-info-card-wrapper .name-and-description-wrapper .price-and-weight-wrapper .total-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.order-product-info-card-wrapper .name-and-description-wrapper .price-and-weight-wrapper .price-per-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.order-product-info-card-wrapper .name-and-description-wrapper .card-btns-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    box-sizing: border-box;
}

.order-product-info-card-wrapper .minus-btn-wrapper {
    overflow: hidden;
}