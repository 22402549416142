.profile-button-wrapper {
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    box-sizing: border-box;
}

.profile-btn-all-wrapper .profile-sheet-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.profile-btn-all-wrapper .react-modal-sheet-backdrop {
    background-color: var(--special-dark-layer, rgba(0, 0, 0, 0.35)) !important;
}

.profile-btn-all-wrapper .react-modal-sheet-container {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.profile-btn-all-wrapper .profile-sheet-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 8px;
}

.profile-btn-all-wrapper .profile-sheet-wrapper .btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.profile-btn-all-wrapper .react-modal-sheet-drag-indicator {
    width: 24px !important;
}

.profile-btn-all-wrapper .react-modal-sheet-header {
    height: 24px !important;
}

.profile-btn-all-wrapper .avatar-section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.profile-btn-all-wrapper .greetings-sheet-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.profile-btn-all-wrapper .greetings-sheet-wrapper .greetings {
    color: var(--text-primary, #2C2D2E);
}

.profile-btn-all-wrapper .greetings-sheet-wrapper .gray-sec {
    color: var(--text-secondary, #5D5E64);
}

.prevent-wrapper {
    pointer-events: none;
    z-index: 99999999999999999 !important;
}