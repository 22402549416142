.nav-bar-order-btn-wrapper {
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    box-sizing: border-box;
}

.nav-bar-order-btn-wrapper .nav-bar-order-btn-icons-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
}