.homepage-wrapper .top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 8px 16px;
    gap: 10px;
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10;
}

.homepage-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    padding-bottom: 64px;
    box-sizing: border-box;
    background: var(--light-colors-light, #F6F9FA);
    z-index: 0;
    position: relative;
}

.homepage-wrapper .restaurant-sections-wrapper {
    padding-bottom: 56px;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.homepage-wrapper-desktop {
    display: flex;
    flex-direction: column;
    padding: 16px 86px 64px 86px;
    box-sizing: border-box;
    gap: 32px;
    margin-top: 80px;
    align-self: center;
    justify-self: center;
    max-width: 1440px;
    width: 100%;
}

.homepage-wrapper-desktop .restaurant-sections-wrapper-desktop {
    padding-bottom: 60px;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 28px;
}