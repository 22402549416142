.food-section-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    padding: 3px 16px 24px;
    box-sizing: border-box;
}

.food-section-wrapper .food-grid {
    display: grid;
    width: 100%;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    row-gap: 16px;
}

.food-section-wrapper-desktop {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding: 3px 16px 24px;
    box-sizing: border-box;
}

.food-section-wrapper-desktop .food-wrapper-desktop {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 32px;
    flex-direction: row;
}