.avatar-wrapper {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--special-green-gradient, linear-gradient(135deg, #00DF5F 0%, #4DF293 100%));
    z-index: 0;
}

.avatar-wrapper:hover {
    cursor: pointer;
}

.avatar-wrapper.desktop {
    width: 128px;
    height: 128px;
    border-radius: 64px;
}

.avatar-wrapper.desktop-btn {
    width: 24px;
    height: 24px;
    border-radius: 12px;
}

.avatar-wrapper.navtiem {
    width: 24px;
    height: 24px;
    border-radius: 12px;
}

.avatar-wrapper.navtiem.nopic {
    background: white !important;
}

.avatar-wrapper .avatar-static-wrapper {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-light);

    color: var(--main-ym-green, #00DF5F);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: InterDisplay-SemiBold, serif;
    font-size: 37px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 129.73% */
    letter-spacing: -0.74px;
}

.avatar-wrapper .avatar-static-wrapper.navitem {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: var(--gray-light);

    font-family: InterDisplay-SemiBold, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.avatar-wrapper .avatar-static-wrapper.desktop {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 116px;
    height: 116px;
    border-radius: 63px;

    color: var(--main-ym-green, #00DF5F);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: InterDisplay-SemiBold, serif;
    font-size: 67px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 129.73% */
    letter-spacing: -0.74px;
}

.avatar-wrapper .avatar-static-wrapper.desktop-btn {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    background: var(--gray-light);

    font-family: InterDisplay-SemiBold, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.avatar-section-wrapper .name {
    text-align: center;
}

.avatar-wrapper .selected-img-wrapper {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 58px ;
    height: 58px;
    border-radius: 32px;
    border-color: white;
    border-style: solid;
    border-width: 3px;
    z-index: 9999;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.avatar-wrapper .selected-img-wrapper.desktop {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 116px ;
    height: 116px;
    border-radius: 63px;
    border-color: white;
    border-style: solid;
    border-width: 6px;
    z-index: 9999;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.avatar-wrapper .selected-img-wrapper.navitem {
    top: 1px;
    left: 1px;
    width: 20px ;
    height: 20px;
    border-radius: 11px;
    border-color: white;
    border-style: solid;
    border-width: 1px;
}

.avatar-wrapper .selected-img-wrapper.desktop-btn {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px ;
    height: 22px;
    border-radius: 11px;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    z-index: 9999;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}