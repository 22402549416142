.order-history-page-wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    position: relative;
}

.order-history-page-wrapper .history-page-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 8px 8px 24px;
}

.order-history-page-wrapper .bottom-grad {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 32px);
    bottom: 52px;
    background: linear-gradient(to bottom, transparent, white);
    height: 18px;
    display: flex;
    pointer-events: none;
    z-index: 9;
}

.order-history-page-wrapper .return-btn-wrapper {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 4px 16px 16px;
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.order-history-page-wrapper .main-hist-wrapper {
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;
    gap: 28px;
}

.order-history-page-wrapper .hist-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    margin-bottom: 24px;
}

.order-history-page-wrapper .hist-text-wrapper .gray {
    color: var(--text-primary, #2C2D2E);
    text-align: center;
}

.order-history-page-wrapper .hist-text-wrapper .gray-sec {
    color: var(--text-tetriary, #87898F);
    text-align: center;
}

.order-history-wrapper-desktop {
    display: flex;
    flex-direction: column;
    padding: 16px 86px 64px 86px;
    box-sizing: border-box;
    gap: 32px;
    margin-top: 80px;
    align-self: center;
    justify-self: center;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.order-history-wrapper-desktop .order-history-wrapper-desktop-popup {
    display: flex;
    flex-direction: column;
    padding: 32px 4px 32px 16px;
    align-items: flex-start;
    gap: 4px;
    box-sizing: border-box;
    box-shadow: 1px 1px 50px 0px rgba(0, 0, 0, 0.20);
    border-radius: 24px;
    position: relative;
    background-color: var(--main-white);
}

.order-history-wrapper-desktop-popup .top-grad {
    content: '';
    position: absolute;
    top: 78px;
    background: linear-gradient(to top, transparent, var(--main-white));
    height: 12px;
    display: flex;
    pointer-events: none;
    width: calc(100% - 30px);
    z-index: 9;
}

.order-history-wrapper-desktop-popup .bottom-grad {
    content: '';
    position: absolute;
    bottom: 91px;
    background: linear-gradient(to bottom, transparent, var(--main-white));
    height: 18px;
    display: flex;
    pointer-events: none;
    width: calc(100% - 30px);
    z-index: 9;
}

.order-history-wrapper-desktop .history-info-desktop {
    width: 100%;
    gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-history-wrapper-desktop .history-info-desktop .info-wrpr {
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}