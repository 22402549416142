.select-item-wrapper {
    display: flex;
    padding: 2px 0;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    box-sizing: border-box;
}

.select-item-wrapper:hover {
    cursor: pointer;
}
