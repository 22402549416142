.qr-main-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden !important;
    position: relative;
}

.qr-main-wrapper .target-wrapper {
    position: absolute;
    width: 188px;
    height: 188px;
}

.qr-wrapper {
    position: relative;
    width: 188px;
    height: 188px;
}

.qr-err-all-wrapper .react-modal-sheet-backdrop {
    background-color: var(--special-dark-layer, rgba(0, 0, 0, 0.35)) !important;
}

.qr-err-all-wrapper .react-modal-sheet-container {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.qr-err-all-wrapper .qr-error-sheet-wrapper {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-top: 16px;
}

.qr-err-all-wrapper .react-modal-sheet-drag-indicator {
    width: 24px !important;
    background: var(--gray-dark, #C6C9D1) !important;
}

.qr-err-all-wrapper .react-modal-sheet-header {
    height: 24px !important;
}

.qr-err-all-wrapper .qr-error-sheet-wrapper .btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}