.profile-page-wrapper {
    display: flex;
    width: 100%;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.profile-page-wrapper .inputs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    align-self: stretch;
    box-sizing: border-box;
}

.profile-page-wrapper .preferences-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;
}

.profile-page-wrapper .grayy {
    color: var(--text-secondary, #5D5E64);
    text-align: left;
}

.profile-page-wrapper .btns-wrapper {
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    position: fixed;
    bottom: 61px;
    overflow: hidden;
    background: white;
}

.profile-page-wrapper .save-btn-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.2s ease-in-out;
    opacity: 1;
}

.profile-page-wrapper .save-btn-wrapper.opacity0 {
    pointer-events: none;
    opacity: 0;
}

.profile-page-wrapper .grad {
    content: '';
    position: fixed;
    background: linear-gradient(to bottom, transparent, white);
    height: 27px;
    width: 100%;
    display: flex;
    pointer-events: none;
    z-index: 9999 !important;
}

.profile-page-wrapper-desktop {
    margin-top: 88px;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: var(--light-colors-light);
}

.profile-page-wrapper-desktop .profile-info-popup {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
    gap: 24px;
    box-sizing: border-box;
    border-radius: 24px;
    background-color: var(--main-white, #FFFFFF);
    position: relative;
}

.profile-page-wrapper-desktop .profile-info-popup .profile-desktop-avatar-wrapper {
    position: absolute;
    top: 0;
    left: -168px;
}

.profile-page-wrapper-desktop .profile-info-popup .profile-desktop-inputs-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
}

.profile-page-wrapper-desktop .profile-info-popup .profile-desktop-comment-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.profile-desktop-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
    flex-wrap: wrap;
}