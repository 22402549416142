.restaurant-page-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-sizing: border-box;
}

.restaurant-page-wrapper.loaded {
    padding-top: 64px;
}

.restaurant-page-wrapper .top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 8px 16px;
    gap: 10px;
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10;
}

.restaurant-page-wrapper .tags-scroll-wrapper {
    height: 40px;
    width: 100%;
    z-index: 9;
    flex-shrink: 0;
    overflow-y: scroll;
    background-color: white;
    display: flex;
    gap: 8px;
    padding-right: 16px;
    padding-left: 16px;
    box-sizing: border-box;
}

.restaurant-page-wrapper .tags-scroll-wrapper.shadowed {
    -webkit-box-shadow: 0px 10px 10px 0px rgba(124, 124, 124, 0.1);
}

.restaurant-page-wrapper .tags-scroll-wrapper::-webkit-scrollbar {
    display:none;
}


.restaurant-page-wrapper .tags-scroll-wrapper:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.10);
}

.restaurant-page-wrapper .tags-scroll-wrapper .pdng {
    width: 8px !important;
    height: 32px !important;
    flex-shrink: 0 !important;
}

.restaurant-page-wrapper-desktop {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 80px;
}

.restaurant-page-wrapper-desktop .tags-scroll-wrapper-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--main-white, #FFFFFF);
    z-index: 9;
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    width: 100%;
}

.restaurant-page-wrapper-desktop .tags-scroll-wrapper-desktop .tags-scroll-internal-wrapper-desktop {
    width: 100%;
    gap: 8px;
    flex-wrap: wrap;
    max-width: 1440px;
    padding-right: 86px;
    padding-left: 86px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.restaurant-page-wrapper-desktop .tags-scroll-wrapper-desktop.shadowed {
    -webkit-box-shadow: 0px 10px 10px 0px rgba(124, 124, 124, 0.1);
}

.restaurant-page-wrapper-desktop .rest-base-flex-wrapper-desktop {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.restaurant-page-wrapper-desktop .rest-base-flex-wrapper-desktop .rest-food-section-desktop-wrapper {
    max-width: 1440px;
    padding-right: 86px;
    padding-left: 86px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 40px;
    gap: 8px;
    z-index: 0;
}