.qr-btn-wrapper {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--main-ym-green, #00DF5F);
    box-sizing: border-box;
}

.qr-btn-wrapper:hover {
    background: var(--on-hover-ym-green, #00C956);
    cursor: pointer;
}

.qr-btn-wrapper:active {
    background: var(--on-click-ym-green, #00B54D);
    cursor: pointer;
}