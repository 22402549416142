.scroll-btn-wrapper {
    position: fixed;
    z-index: 9999;
    right: -100px;
    transition: 0.2s all ease-in-out;
}

.scroll-btn-wrapper.opened {
    right: 16px;
}

.scroll-btn-wrapper.normal {
    bottom: 80px;
}

.scroll-btn-wrapper.shifted {
    bottom: 144px;
}